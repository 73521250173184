import React from "react";
import contactBackground from "../../../assets/images/contact/contact-bg-1.jpg";
// import services bg and use conditionally
import servicesBackground from "../../../assets/images/services/services_bg.jpg";

const bgMap = {
  contact: contactBackground,
  services: servicesBackground,
}

export default function Hero({ pageTitle, breadcrumbs = [], pageKey = '' }) {
  return (
    <section
      className="page-banner bgs-cover overlay pt-50"
      style={{ backgroundImage: `url(${bgMap[pageKey]})` }}
    >
      <div className="container">
        <div className="banner-inner">
          <h1 className="page-title">{pageTitle}</h1>
          <nav aria-label="breadcrumb">
            <ol className="breadcrumb">
              {breadcrumbs &&
                breadcrumbs.length > 0 &&
                breadcrumbs.map((item) => (
                  <li key={Math.random()} className="breadcrumb-item">
                    <a href={item.path}>{item.name}</a>
                  </li>
                ))}
            </ol>
          </nav>
        </div>
      </div>
    </section>
  );
}
