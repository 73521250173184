import React from "react";

function FeatureSection() {
  return (
    <section className="featured-section bgs-cover pt-80 rpt-150 pb-120 rpb-100">
      <div className="container">
        <div className="row align-items-center">
          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-0-2s">
              <div className="icon">
                <i className="fas fa-server"></i>
              </div>
              <div className="feature-content">
                <h5>DATA Center Infrastructure</h5>
                <p>
                UTS - Infrastructure support provides clients and agencies with the most scalable, feasible and reliable infrastructure possible.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-0-4s">
              <div className="icon">
                <i className="fas fa-lock"></i>
              </div>
              <div className="feature-content">
                <h5>Cyber Security</h5>
                <p>
                  UTS - Cyber Security Solution provides customer with Real-Time Awareness, Enterprise Visibility and Behavior Analytics in order to reduce operational cost.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-0-6s">
              <div className="icon">
                <i className="fas fa-tty"></i>
              </div>
              <div className="feature-content">
                <h5>I.T. Collaboration</h5>
                <p>
                UTS - I.T. Collaboration practices specializes in calling, meeting, virtual assistance, messaging, as well as Audio Visual Design and Integration.
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-0-8s">
              <div className="icon">
                <i className="fa fa-cog"></i>
              </div>
              <div className="feature-content">
                <h5>Managed and Other Services</h5>
                <p>
                  UTS - Managed Services comprises of a complete array of client based on business outcomes and Services Level Agreements (SLAs). 
                </p>
              </div>
            </div>
          </div>
          <div className="col-xl-4 col-md-6">
            <div className="feature-item wow fadeInUp delay-1-0s">
              <div className="icon">
                <i className="fas fa-briefcase"></i>
              </div>
              <div className="feature-content">
                <h5>Product Line</h5>
                <p>
                  UTS - Hosting a wide range of I.T Hardware and Software Products to enable you to run your business successfully and without interuption. 
                </p>
                <a href="mailto:sales@uniquetsys.com" className="learn-more">
                  Request Product Line<i className="fas fa-arrow-right"></i>
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default FeatureSection;
