import React from "react";
import GetInTouchSection from "../../AboutPages/AboutOne/GetInTouchSection";
import Layouts from "../../helpers/Layouts/index";
import FeatureSection from "../../HomeOne/FeatureSection";
import PricingSection from "../../HomeOne/PricingSection";
import WorkProcessSection from "./WorkProcessSection";

export default function ServiceOne() {
  return (
    <Layouts
      pageTitle="PRODUCTS AND SERVICES"
      breadcrumbs={[
        { name: "Home", path: "/" },
        { name: "Services", path: "#/services" },
      ]}
      pageKey="services"
    >
      <FeatureSection />
    </Layouts>
  );
}
