import React, { useRef } from "react";
import SliderCom from "../helpers/SliderCom";

import img_item_1 from '../../assets/images/gallery/item-1.jpg';
import img_item_2 from '../../assets/images/gallery/item-2.jpg';
import img_item_3 from '../../assets/images/gallery/item-3.jpg';
import img_item_4 from '../../assets/images/gallery/item-4.jpg';
import img_item_5 from '../../assets/images/gallery/item-5.jpg';
import img_item_6 from '../../assets/images/gallery/item-6.jpg';

export default function GallerySection() {
  const sliderRef = useRef(null);
  const settings = {
    dots: false,
    infinite: true,
    autoplay: true,
    autoplaySpeed: 5000,
    arrows: false,
    speed: 1000,
    slidesToShow: 3,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 991,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 575,
        settings: {
          slidesToShow: 1,
        },
      },
    ],
  };
  const prevHandler = () => sliderRef.current.slickPrev();
  const nextHandler = () => sliderRef.current.slickNext();
  return (
    <section className="gallery-section-three overlay pt-120 rpt-95 pb-105 rpb-85">
      <div className="container">
        <div className="section-title gellery-section-title mb-55 wow fadeInUp delay-0-2s">
          <h2>Our Partners</h2>
          <div className="gallery-carousel-arrow mt-25">
            <button
              onClick={prevHandler}
              type="button"
              className="gallery-prev slick-arrow"
            >
              <i className="fas fa-arrow-left"></i>
            </button>
            <button
              onClick={nextHandler}
              type="button"
              className="gallery-next slick-arrow"
            >
              <i className="fas fa-arrow-right"></i>
            </button>
          </div>
        </div>
        <div className="gallery-three-wrap wow fadeInRight delay-0-2s">
          <SliderCom selector={sliderRef} settings={settings}>
            <div className="gallery-item style-three">
              <img
                src={img_item_1}
                alt="Gallery"
              />
            </div>
            <div className="gallery-item style-three">
              <img
                src={img_item_2}
                alt="Gallery"
              />
            </div>
            <div className="gallery-item style-three">
              <img
                src={img_item_3}
                alt="Gallery"
              />
            </div>
            <div className="gallery-item style-three">
              <img
                src={img_item_4}
                alt="Gallery"
              />
            </div>
            <div className="gallery-item style-three">
              <img
                src={img_item_5}
                alt="Gallery"
              />
            </div>
            <div className="gallery-item style-three">
              <img
                src={img_item_6}
                alt="Gallery"
              />
            </div>
          </SliderCom>
        </div>
      </div>
    </section>
  );
}
