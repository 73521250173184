import React from "react";
import { Link } from "react-router-dom";
import Background from "../../../../assets/images/footer/footer-bg-map.png";
import logo from "../../../../assets/images/logos/logo_ucraft.png";

export default function FooterHomeTwo({ className }) {
  return (
    <footer
      className={`main-footer footer-two bgs-cover text-white ${
        className || ""
      }`}
      style={{ backgroundImage: `url(${Background})` }}
    >
      <div className="container">
        <div className="footer-widget-area pt-60 pb-20">
          <div className="row">
          <div className="col-lg-3"></div>
            <div className="col-lg-4">
              <div className="footer-widget about-widget">
                <div className="footer-logo mb-35">
                  <a href="/">
                    <img src={logo} alt="Logo" />
                  </a>
                </div>
                <div className="text">
                  <p>Copyright 2023 UTS All Rights Reserved.</p>
                </div>
              </div>
            </div>
            <div className="col-lg-2">
              <div className="footer-widget link-widget ml-20 rml-0">
                <h4 className="footer-title">Page Links</h4>
                <ul className="list-style-two">
                  <li>
                    <Link to="/">Home</Link>
                  </li>
                  <li>
                    <Link to="/services">
                      services
                    </Link>
                  </li>
                  <li>
                  <Link to="/contact">contact us</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-lg-3">
            </div>
          </div>
        </div>
      </div>
    </footer>
  );
}
