import React from "react";
import background from "../../assets/images/contact/message.jpg";
import Layouts from "../helpers/Layouts";

export default function Contact() {
  return (
    <>
      <Layouts
        pageTitle="Contact us"
        breadcrumbs={[
          { name: "home", path: "/" },
          { name: "contact", path: "#/contact" },
        ]        
      }
      pageKey="contact"
      >
        <section className="contact-page py-60 rpy-100">
          <div className="container">
            <div className="contact-info-area mb-80">
              <div className="contact-info-item wow fadeInUp delay-0-2s">
                <i className="far fa-map"></i>
                <p>248 Wellman Ave, <br /> North Chelmsford,<br /> MA 01863, USA</p>
              </div>
              <div className="contact-info-item wow fadeInUp delay-0-4s">
                <i className="far fa-envelope"></i>
                <p>
                  <a href="mailto:sales@uniquetsys.com">sales@uniquetsys.com</a>
                </p>
              </div>
              <div className="contact-info-item wow fadeInUp delay-0-6s">
                <i className="fas fa-phone-alt"></i>
                <p>
                  <a href="callto:+1(978)6745120">+1(978) 674-5120</a>
                </p>
              </div>
              <div className="contact-info-item wow fadeInUp delay-0-6s">
                <i className="fas fa-info-circle"></i>
                <p>
                  SAM Information
                  <p>
                    <small>Entity ID: <b>M3XDXWTGJPV4</b></small> <br />
                    <small>CAGE Code: <b>9MCU7</b></small>
                  </p>
                </p>
              </div>
            </div>
          </div>
        </section>
      </Layouts>
    </>
  );
}
